import React, { useCallback } from 'react';
import { Button, EditorShell, Form, Modal } from '@cognitiv/cassiopeia-ui';
import { selectModal } from 'ducks/modals/selectors';
import { updateModal } from 'ducks/modals/slices';
import { Stack } from 'modals/utility/Stack';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import cn from 'modals/utility/Modal.module.scss';
export const Error = () => {
  const dispatch = useAppDispatch();
  const { name, stack, raw, is_open } = useAppSelector((state) => selectModal(state, 'error'));

  const onClose = useCallback(() => {
    dispatch(updateModal({ error: { is_open: false } }));
  }, [dispatch]);

  const title = name || 'Something went wrong!';

  return (
    <>
      <Modal title={title} is_open={is_open} identifier="error" width={640} onClose={onClose}>
        <Form>
          <div className={cn.sql}>
            <EditorShell theme_identifier="html" min_height={200} value={raw || stack || ''} vertical_resize horizontal_resize min_width={700} />
          </div>
          <div className={cn.footer}>
            <div className={cn.grow} />
            <Button onClick={onClose}>Ok</Button>
          </div>
        </Form>
      </Modal>
      <Stack />
    </>
  );
};
